import styled from '@emotion/styled/macro';
import {Link, matchPath} from 'react-router-dom';
import logo from '../images/logo.png';

const LogoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  '@media all and (max-width: 400px)': {
    flexDirection: 'column',
  }
});

const Logo = styled('img')({
  display: 'block',
  width: 50,
  marginRight: 18,
  '@media all and (max-width: 400px)': {
    marginRight: 0,
  }
});

const Title = styled('h1')({
  fontSize: 38,
  margin: 0,
  fontWeight: 600
})

const Tagline = styled('div')({
  textAlign: 'center',
  fontSize: 20,
  lineHeight: 1.4,
  margin: '20px 40px',
  '@media all and (max-width: 400px)': {
    fontSize: 16,
    marginTop: 8
  }
});

const Affiliation = styled('div')({
  fontSize: 13,
  textAlign: 'center',
  marginTop: 10,
  fontStyle: 'italic',
  color: '#a2a2a2'
});

const Buttons = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
  maxWidth: 400,
  margin: '0 auto',
  '@media all and (max-width: 480px)': {
    flexDirection: 'column'
  }
});

const Button = styled('a')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: '#fff',
  background: '#333',
  borderRadius: 4,
  padding: '10px 15px',
  textDecoration: 'none',
  'i': {
    fontSize: 30,
    marginRight: 15
  },
  'span': {
    display: 'block',
    lineHeight: 'normal'
  },
  'span:first-child': {
    fontSize: 11,
    textTransform: 'uppercase',
    marginBottom: 1
  },
  'span:last-child': {
    fontSize: 20
  },
  '&:hover': {
    background: '#000'
  },
  '@media all and (max-width: 480px)': {
    '&:first-child': {
      marginBottom: 10,
    }
  }
});

export default function Header () {
  const isAndroidUpdate = !!matchPath(window.location.pathname, {
    path: '/android-update',
    exact: true
  });

  return (
    <div css={{marginTop: 30}}>
      <LogoContainer>
        <Link to="/">
          <Logo src={logo} alt="MBTA Rail" />
        </Link>
        <Title>MBTA Rail</Title>
      </LogoContainer>


      {!isAndroidUpdate && (
        <>
          <Tagline>
            Real-time train tracking for the MBTA Commuter Rail.
          </Tagline>
          <Buttons>
            <Button href="https://play.google.com/store/apps/details?id=com.ech.bostonrail" target="_blank">
              <i className="fab fa-android"></i>
              <div>
                <span>Android app on</span>
                <span>Google Play</span>
              </div>
            </Button>
            <Button href="https://itunes.apple.com/us/app/mbta-commuter-rail-tracker/id991877851?mt=8" target="_blank">
              <i className="fab fa-apple"></i>
              <div>
                <span>Download on the</span>
                <span>iOS App Store</span>
              </div>
            </Button>
          </Buttons>
          <Affiliation>
            Not affiliated with the MBTA, Keolis, or MassDOT!
          </Affiliation>
        </>
      )}
    </div>
  );
}
