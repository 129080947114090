import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Home from './views/Home';
import Privacy from './views/Privacy';
import Terms from './views/Terms';
import AndroidUpdate from './views/AndroidUpdate';
import Layout from './views/Layout';
import './App.css';

class App extends React.Component {
  render () {
    return (
      <Router>
        <Layout>
          <Route exact path="/" component={Home} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/android-update" component={AndroidUpdate} />
        </Layout>
      </Router>
    );
  }
}

export default App;
