import styled from '@emotion/styled/macro';

const Container = styled.div`
  text-align: center;
  font-size: 14px;
`;

const Affiliation = styled.div({
  fontSize: 12,
  marginTop: 3,
  color: '#a2a2a2'
});

export default function Footer () {
  return (
    <Container>
      <a href="/privacy">Privacy Policy</a> &nbsp;/&nbsp;
      <a href="/terms">Terms of Use</a>
      <Affiliation>
        Not affiliated with the MBTA, Keolis, or MassDOT!
      </Affiliation>
    </Container>
  );
}
