import React from "react";
import Section, { Container } from "../components/Section";

export default function Terms() {
  return (
    <Section grey>
      <Container>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "22px",
            textAlign: "center",
            marginTop: 0,
          }}
        >
          Android update &ndash; Dec. 28, 2023
        </p>
        <p>
          <strong>TL;DR:</strong> Please install the new version of MBTA Rail,
          now named Boston Rail, by{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.ech.bostonrail&hl=en_US"
            target="_blank"
            rel="noreferrer"
          >
            clicking here
          </a>
          . It's exactly the same as before, just with a different name. <strong>Once installed, please email <a href="mailto:info@mbtarail.app?subject=free+android+account+request">info@mbtarail.app</a> for a free account!</strong>
        </p>
        <p>
          Longer version: Google pulled MBTA Rail from the Android app store
          over concerns that the use of the word "MBTA" impersonates a
          government entity.
        </p>
        <p>
          Our attempts to resolve this issue failed, so we've been forced to
          rename the app and release it as a brand new app in the app store.
        </p>
        <p>
          Please install the new version of MBTA Rail, now named Boston Rail, by{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.ech.bostonrail&hl=en_US"
            target="_blank"
            rel="noreferrer"
          >
            clicking here
          </a>
          . While the existing MBTA Rail app may continue to work, it will no
          longer receive updates to schedules, bug fixes, or new features.
        </p>
        <p>Thank you for your understanding and support!</p>
      </Container>
    </Section>
  );
}
