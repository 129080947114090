import styled from '@emotion/styled/macro';

const Section = styled('div')({
  padding: '25px'
}, props => props.grey && ({
  background: '#f6f6f6',
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: '#e6e6e6'
}), props => props.center && ({
  textAlign: 'center'
}));

export const Container = styled('div')({
  maxWidth: 900,
  margin: '0 auto'
});

export default Section;
