import React from 'react';
import styled from '@emotion/styled/macro';
import Section, {Container} from '../components/Section';

const H1 = styled('h1')({
  fontSize: 34,
  marginTop: 0
});

const H2 = styled('h1')({
  fontSize: 26
});

const H3 = styled('h1')({
  fontSize: 20
});

export default function Home () {
  return (
    <Section grey>
      <Container>
        <H1>Privacy Policy</H1>

        <p>Last updated December 13, 2017.</p>
        <p>MBTA Rail (&quot;we&quot; or &quot;us&quot; or &quot;our&quot;) respects the privacy of our users (&quot;user&quot;
          or &quot;you&quot;). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when
          you visit our mobile application (the &quot;Application&quot;). Please read this Privacy Policy carefully. IF YOU DO
          NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION.</p>
        <p>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about
          any changes by updating the &quot;Last updated&quot; date of this Privacy Policy. You are encouraged to periodically
          review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be
          subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of
          the Application after the date such revised Privacy Policy is posted.</p>
        <p>This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application or
          make payments, including any in-game virtual items, which may also collect and use data about you. We are not
          responsible for any of the data collected by any such third party.</p>

        <H2>Collection of your Information</H2>
        <p>We may collect information about you in a variety of ways. The information we may collect via the Application depends on the content and materials you use, and includes:</p>

        <H3>Derivative Data</H3>
        <p>Information our servers automatically collect when you access the Application, such as your native actions that are integral to the Application, including liking, re-blogging, or replying to a post, as well as other interactions with the Application and other users via server log files.</p>

        <H3>Mobile Device Data</H3>
        <p>Device information such as your mobile device ID number, model, and manufacturer, version of your operating system, location, and any other data you choose to provide.</p>

        <H3>Push Notifications</H3>
        <p>We may request to send you push notifications regarding your account or the Application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device&apos;s settings.</p>

        <H2>Use of your Information</H2>
        <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Application to:</p>
        <p>* Enable user-to-user communications.</p>
        <p>* Monitor and analyze usage and trends to improve your experience with the Application.</p>
        <p>* Perform other business activities as needed.</p>

        <H2>Disclosure of your Information</H2>
        <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>

        <H3>By Law or to Protect Rights</H3>
        <p>If we believe the release of information about you is necessary to respond to legal process, to investigate or
          remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share
          your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging
          information with other entities for fraud protection and credit risk reduction.</p>

        <H3>Interactions with Other Users</H3>
        <p>If you interact with other users of the Application, those users may see your name (if you provide one) and the information that you post.</p>

        <H3>Business Partners</H3>
        <p>We may share your information with our business partners to offer you certain products, services or promotions.</p>

        <H3>Other Third Parties</H3>
        <p>We may share your information with advertisers and investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.</p>

        <H3>Sale or Bankruptcy</H3>
        <p>If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may
          transfer your information to the successor entity. If we go out of business or enter bankruptcy, your information
          would be an asset transferred or acquired by a third party. You acknowledge that such transfers may occur and that
          the transferee may decline honor commitments we made in this Privacy Policy.</p>
        <p>We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have
          no authority to manage or control third-party solicitations. If you no longer wish to receive correspondence, emails
          or other communications from third parties, you are responsible for contacting the third party directly.</p>

        <H2>Tracking Technologies</H2>

        <H3>Website Analytics</H3>
        <p>We may also partner with selected third-party vendors, such as Google Analytics, Twitter Fabric, to allow tracking
          technologies and remarketing services on the Application through the use of first party cookies and third-party
          cookies, to, among other things, analyze and track users&apos; use of the Application, determine the popularity of
          certain content, and better understand online activity. By accessing the Application, you consent to the collection
          and use of your information by these third-party vendors. You are encouraged to review their privacy policy and
          contact them directly for responses to your questions. We do not transfer personal information to these third-party
          vendors. However, if you do not want any information to be collected and used by tracking technologies, you can visit
          the third-party vendor or Network Advertising Initiative Opt-Out Tool or Digital Advertising Alliance Opt-Out Tool.</p>
        <p>You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing
          or otherwise altering your browser&apos;s cookies files may also clear certain opt-out cookies, plug-ins, or
          settings.</p>

        <H2>Third-party Websites</H2>
        <p>The Application may contain links to third-party websites and applications of interest, including advertisements and
          external services, that are not affiliated with us. Once you have used these links to leave the Application, any
          information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the
          safety and privacy of your information. Before visiting and providing any information to any third-party websites,
          you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that
          website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We
          are not responsible for the content or privacy and security practices and policies of any third parties, including
          other sites, services or applications that may be linked to or from the Application.</p>

        <H2>Google Maps</H2>
        <p>This mobile application may use Google Maps APIs. By using our Maps API Implementation, you agree to be bound by Google&apos;s Terms of Service.</p>

        <H2>Security of your Information</H2>
        <p>We use administrative, technical, and physical security measures to help protect your personal information. While we
          have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our
          efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and
          misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.</p>

        <H2>Policy for Children</H2>
        <p>We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.</p>

        <H2>Contact</H2>
        <p>If you have questions or comments about this Privacy Policy, please contact us info@mbtatracker.com.</p>
      </Container>
    </Section>
  );
}
