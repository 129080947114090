import {Fragment} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Section, {Container} from '../components/Section';

export default function Layout ({children}) {
  return (
    <Fragment>
      <Section>
        <Container>
          <Header />
        </Container>
      </Section>
      {children}
      <Section>
        <Container>
          <Footer />
        </Container>
      </Section>
    </Fragment>
  );
}
