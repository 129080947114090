import {Fragment} from 'react';
import styled from '@emotion/styled/macro';
import Section, {Container} from '../components/Section';

import marketing from '../images/marketing.png';
import marketing2x from '../images/marketing@2x.png';
import map from '../images/view-map.png';
import map2x from '../images/view-map@2x.png';
import history from '../images/view-history.png';
import history2x from '../images/view-history@2x.png';
import reports from '../images/view-reports.png';
import reports2x from '../images/view-reports@2x.png';

const MarketingImage = styled('img')({
  width: '100%',
  maxWidth: 700,
  margin: '0 auto'
});

const Screenshot = styled('img')({
  width: '100%',
  minWidth: 200
});

const Columns = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

const Column = styled('div')({
});

const ScreenshotColumn = styled('div')({
  '@media all and (max-width: 500px)': {
    display: 'none'
  }
}, props => props.right && ({
  marginLeft: 50
}), props => props.left && ({
  marginRight: 50
}));

const Feature = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 40,
  '&:last-child': {
    marginBottom: 0
  }
});

const FeatureIcon = styled('i')({
  minWidth: 50,
  fontSize: 40,
  color: '#3e73d4',
  marginRight: 20,
  textAlign: 'center'
});

const FeatureText = styled('div')({
  color: '#7c7c7c',
  fontSize: 17,
  lineHeight: 1.5
});

const FeatureTitle = styled('h3')({
  color: '#000',
  fontSize: 22,
  fontWeight: 400,
  margin: '0 0 5px 0',
  padding: 0
});

export default function Home () {
  return (
    <Fragment>
      <Section grey center>
        <MarketingImage src={marketing} srcSet={`${marketing2x} 2x`} alt="" />
      </Section>

      <Section>
        <Container>
          <Columns>
            <Column>
              <Feature>
                <FeatureIcon className="fa fa-heartbeat" />
                <FeatureText>
                  <FeatureTitle>Real-time location data</FeatureTitle>
                  See your train's current stop, next stop, predicted arrival times, GPS location, speed, and more.
                </FeatureText>
              </Feature>
              <Feature>
                <FeatureIcon className="fa fa-clipboard" />
                <FeatureText>
                  <FeatureTitle>Departures board</FeatureTitle>
                  Boarding track numbers display next to each trip originating from North and South Station. Also view a dedicated departures screen to see the entire departures board.
                </FeatureText>
              </Feature>
              <Feature>
                <FeatureIcon className="fa fa-users" />
                <FeatureText>
                  <FeatureTitle>Crowd-sourced data</FeatureTitle>
                  Is a prediction wrong? Train delayed mid-trip? Wondering if your train is standing room only? Discuss through the "Reports" button next to each trip.
                </FeatureText>
              </Feature>
            </Column>
            <ScreenshotColumn right>
              <Screenshot src={map} srcSet={`${map2x} 2x`} alt="Map" />
            </ScreenshotColumn>
          </Columns>
        </Container>
      </Section>

      <Section grey>
        <Container>
          <Columns>
            <ScreenshotColumn left>
              <Screenshot src={reports} srcSet={`${reports2x} 2x`} alt="Passenger reports" />
            </ScreenshotColumn>
            <Column>
              <Feature>
                <FeatureIcon className="fas fa-map-marker-alt" />
                <FeatureText>
                  <FeatureTitle>Maps</FeatureTitle>
                  Map integration shows you where your train and other in-progress trains are, complete with rail line overlays and stop markers.
                </FeatureText>
              </Feature>
              <Feature>
                <FeatureIcon className="fas fa-exclamation-triangle" />
                <FeatureText>
                  <FeatureTitle>Service alerts</FeatureTitle>
                  View current and upcoming service alerts for each trip.
                </FeatureText>
              </Feature>
              <Feature>
                <FeatureIcon className="fas fa-calendar" />
                <FeatureText>
                  <FeatureTitle>Schedules</FeatureTitle>
                  A full schedule view for each trip with predicted and scheduled arrival times.
                </FeatureText>
              </Feature>
            </Column>
          </Columns>
        </Container>
      </Section>

      <Section>
        <Container>
          <Columns>
            <Column>
              <Feature>
                <FeatureIcon className="fas fa-analytics" />
                <FeatureText>
                  <FeatureTitle>History</FeatureTitle>
                  View a history of your train's on-time percentage, boarding tracks, and coach numbers.
                </FeatureText>
              </Feature>
              <Feature>
                <FeatureIcon className="fas fa-clock" />
                <FeatureText>
                  <FeatureTitle>Late vs. delayed</FeatureTitle>
                  We tell you how late a train is running before an official delay is announced.
                </FeatureText>
              </Feature>
              <Feature>
                <FeatureIcon className="fas fa-guitar" />
                <FeatureText>
                  <FeatureTitle>CapeFLYER & Gilette Stadium</FeatureTitle>
                  Track your trips to the Cape or to Gilette Stadium for special events.
                </FeatureText>
              </Feature>
            </Column>
            <ScreenshotColumn right>
              <Screenshot src={history} srcSet={`${history2x} 2x`} alt="Trip history" />
            </ScreenshotColumn>
          </Columns>
        </Container>
      </Section>
    </Fragment>
  );
}
